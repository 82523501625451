<template>
  <!-- Main content -->
  <section class="section">
    <div class="row center-block">
      <div class="col-md-12">
        <div class="box box-info">
          <div class="box-header with-border content">
            <h2>
              @{{ this.$route.params.ident }}
              <span v-if="isSelf" class="badge">YOU</span>
            </h2>
          </div>
          <!-- /.box-header -->
          <div class="box-body">
            <b-table
              v-if="listData"
              :data.sync="listData"
              :paginated="true"
              :per-page="10"
              :mobile-cards="true"
              :striped="true"
              :selectable="false"
              :sort="false"
            >
              <template slot-scope="props">
                <b-table-column field="name" label="List" sortable>
                  <h4>{{ props.row.title }} </h4>
                  <p>
                    {{ props.row.word_count || 0 }} Word(s)
                    <span v-if="props.row.favourite">❤️</span>
                  </p>
                </b-table-column>
                <b-table-column field="rating" label="Rating" sortable>
                  <span style="display:none">props.row.rating</span>
                  <star-rating
                    v-model="props.row.rating"
                    :item-size="18"
                    active-color="#ffdf00"
                    border-color="transparent"
                    :spacing="-5"
                    :read-only="true"
                    :show-rating="false"
                    :inline="true"
                  />
                </b-table-column>
                <b-table-column field="actions" label="">
                  <button v-if="props.row.fav == 1 && !isSelf" class="btn button is-link is-pulled-right" type="button" @click.prevent="removeFavourite(props.row)">
                    Un-{{ $store.state.user.locale === 'en_US' ? 'Favorite' : 'Favourite' }} ❤️
                  </button>
                  <button v-else-if="!isSelf" class="btn button is-pulled-right" type="button" @click.prevent="addFavourite(props.row)">
                    Add {{ $store.state.user.locale === 'en_US' ? 'Favorite' : 'Favourite' }} ❤️
                  </button>
                </b-table-column>
                <b-table-column field="details" label="">
                  <router-link tag="button" :to="'/list/' + props.row.ident" class="btn button is-link is-pulled-right" type="button">
                    Details
                  </router-link>
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered content">
                    <p>
                      <b-icon
                        custom-class="far"
                        pack="fa"
                        icon="frown"
                        size="is-large"
                      />
                    </p>
                    <p>Nothing here.</p>
                  </div>
                </section>
              </template>
            </b-table>
            <div v-else class="alert">
              <b>{{ response }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
</template>

<script>
import { StarRating } from 'vue-rate-it'
import { request } from '@/edshed-common/api'

export default {
  name: 'Lists',
  components: {
    StarRating
  },
  data (router) {
    return {
      loading: '',
      listData: null,
      isFavourite: false,
      response: 'Data Loading...'
    }
  },
  computed: {
    isSelf () {
      const pageUser = this.$route.params.ident
      const selfIdent = this.$store.state.user.username
      console.log(pageUser)
      console.log(selfIdent)

      return pageUser.toLowerCase() === selfIdent.toLowerCase()
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getListData()
    })
  },
  methods: {
    getListData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'users/' + this.$route.params.ident + '/lists', null)
        .then((response) => {
          const data = response.data
          this.listData = data.lists.map(this.numberIfy)
          // console.log('readerData: ' + this.readerData)
          if (!this.listData || this.listData.length === 0) {
            this.response = 'No Lists'
          }
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    addFavourite (list) {
      request('PUT', 'lists/' + list.ident + '/favourite', null)
        .then((response) => {
          list.fav = 1
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    removeFavourite (list) {
      const confirm = window.confirm('Are you sure you want to unfavourite this list?')
      if (confirm) {
        request('DELETE', 'lists/' + list.ident + '/favourite', null)
          .then((response) => {
            list.fav = 0
          })
          .catch((error) => {
            console.log(error)
            this.$store.commit('TOGGLE_LOADING')
            this.toggleLoading()
            if (error.response.status === 403) {
              console.log('FORBIDDEN')
              this.$router.push('/logout')
            }
            this.response = 'Details incorrect'
          })
      }
    },
    numberIfy (item, index) {
      item.rating = parseFloat(item.rating)
      return item
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    }
  }
}
</script>
<style>

</style>
